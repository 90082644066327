<template>
  <div class="container">
    <div class="table">
        <div class="table__header">
            <div class="deviceName">장치명</div>
            <div class="state">방재상태</div>
            <div class="date">날짜</div>
            <div class="supervisor">관리자명</div>
            <div classs="settings"><i class="fa-solid fa-pen-to-square"></i></div>
        </div>
        <div v-for="(data, index) in test" :key="index" class="table__contents">
            <div class="deviceName">{{ data.deviceName }}</div>
            <div class="state">{{ data.state }}</div>
            <div class="date">{{ data.date }}</div>
            <div class="supervisor">{{ data.supervisor }}</div>
            <div classs="settings"><i class="fa-solid fa-gear"></i></div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: "HistoryManage",
    data() {
        return {
            test: [
                {
                    siteName: "북면",
                    deviceName: '하천리 1번',
                    state: '방재 완료',
                    date: '2023-09-04',
                    supervisor: '김OO'
                },
                {
                    siteName: "북면",
                    deviceName: '하천리 77번',
                    state: '파쇄 완료',
                    date: '2023-09-02',
                    supervisor: '최OO'
                },
                {
                    siteName: "북면",
                    deviceName: '호명리 119번',
                    state: '파쇄 대기중',
                    date: '2023-09-01',
                    supervisor: '김OO'
                },
                {
                    siteName: "북면",
                    deviceName: '호명리 34번',
                    state: '선별 대기중',
                    date: '2023-08-31',
                    supervisor: '최OO'
                },
                {
                    siteName: "북면",
                    deviceName: '호명리 133번',
                    state: '선별 대기중',
                    date: '2023-08-31',
                    supervisor: '이OO'
                },
                {
                    siteName: "북면",
                    deviceName: '호명리 145번',
                    state: '파쇄 완료',
                    date: '2023-08-30',
                    supervisor: '이OO'
                },
                {
                    siteName: "북면",
                    deviceName: '호명리 12번',
                    state: '벌채 완료',
                    date: '2023-08-31',
                    supervisor: '최OO'
                }
            ]
        }
    }

}
</script>

<style scoped lang="scss">
.table {
    .table__header {
        display: flex;
        border-bottom: 1px solid gray;
        margin-bottom: 1.4%;
        height: 60px;
        text-align: center;
        align-items: center;
        font-size: 20px;
        font-weight: 700;
        .deviceName {
            width: 20%;
        }
        .state {
            width: 40%;
        }
        .date {
            width: 20%;

        }
        .supervisor {
            width: 15%;
        }
        .settings {
            width: 5%;
        }
    }
    .table__contents {
        display: flex;
        text-align: center;
        align-items: center;
        font-size: 16px;
        margin-bottom: 1.6%;
        .deviceName {
            width: 20%;
        }
        .state {
            width: 40%;
        }
        .date {
            width: 20%;

        }
        .supervisor {
            width: 15%;
        }
        .settings {
            width: 5%;
        }
    }
}


</style>