<template>
  <div class="container" id="newDeviceContainer">
    <!-- tabs -->
    <ul class="nav nav-tabs">
      <li :class="{ 'active-tab': activeTab === '#firsttab' }" @click="changeTab('#firsttab')">
        <a href="#firsttab" data-toggle="tab"><i class="fa-solid fa-image" id="tab-icon"></i> 사진 </a>
      </li>
      <li :class="{ 'active-tab': activeTab === '#secondtab' }" @click="changeTab('#secondtab')">
        <a href="#secondtab" data-toggle="tab"><i class="fa-solid fa-list" id="tab-icon"></i> 이력 </a>
      </li>
    </ul>
  
    <!-- content -->
    <div class="tab-content">
      <div class="tab-pane active" id="firsttab">
        <br />
        <DetailImages :deviceImages="deviceImages"/>
      </div>
      <div class="tab-pane" id="secondtab">
        <HistoryManage />
      </div>
    </div>
  </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import loopback from '@/services/loopback';
import moment from 'moment';
import utils from '@/services/utils';
import EventBus from '@/services/EventBus'

import DetailImages from '@/views/pwdm/DetailImages'
import HistoryManage from '@/views/pwdm/HistoryManage'
import EventLog from '@/views/srds/EventLog'


export default {
  name: "PWDMInfoTab",
  extends: EventLog,
  components: {
    HistoryManage,
    DetailImages
  },
  data() {
    return {
      activeTab: '#firsttab',
      deviceImages: [],
      select_device_guid: '',
      device: [],
      storage: "flowPhotos"
    }
  },
  mounted() {
    EventBus.$on("send", this.takeData)
  },
  methods: {
    changeTab(tabName) {
      this.activeTab = tabName;
    },
    takeData(data) {
      this.device_guid = data
      this.getDeviceInfo(data)
    },
    getDeviceInfo(device_guid) {
      this.$store.dispatch('rest/findById', {model: 'devices', guid: device_guid})
        .then(device => this.device = device)
      this.getDeviceImages()
      console.log("this.deviceImages : ", this.deviceImages)
    },
    getDeviceImages() {
      var self = this;
      this.deviceImages = [];
      var download_url = '{0}/{1}/{2}/download'.format(loopback.defaults.baseURL, this.storage, this.device_guid);
        var filter = {
          where: {
            and: [{
                device_guid: self.device_guid
              },
            ]
          },
          order: 'created_at desc',
          limit: 2,
        }

        self.$store.dispatch('rest/find', {model:'photos',filter:filter})
          .then(function(response) {
            var pushImage = function(info) {
              self.deviceImages.push({
                url: download_url + '/' + info.file_name,
                // created_at: moment(info.created_at).format('YYYY년 MM월 DD일 HH시 mm분 ss초')
                created_at: moment(info.created_at).format('YYYY년 MM월 DD일'),
                created_at_thumb: moment(info.created_at).format('YYYY-MM-DD'),
                created_at_time: moment(info.created_at).format('HH시 mm분 ss초')
              })
            }
            for (var i = 0; i < response.length; i++) {
              var info = response[i]
              if (_.isUndefined(info.storage)) continue;
              pushImage(info);
            }
          if (self.deviceImages.length == 0) {
            var network_type = self.device.network_type;
            download_url = 'img/tech9_dev.png'; 
            switch (network_type.toLowerCase()) {
              case 'nb-iot': case 'cat.m1': download_url = 'img/tech9_plug.png'; break; 
              case 'lora': download_url = 'img/tech9_lora.jpg'; break;
            }
            self.deviceImages.push({
              url: download_url
            })
          }
        })
    }
  }

}
</script>

<style scoped lang="scss">
#newDeviceContainer{
  display: flex; 
  flex-direction: column; 
  justify-content: center; 
  padding: 1%;

}
#first {
  display: flex;
}

#tab-icon{
  margin-right: 10%;
  margin-left: 5%;
}

.nav-tabs{
  border-bottom: 1px solid #eee;
  height: 45px;
}

.nav-tabs li {
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 8px 10px 3px 8px;
  width: 85px;
  margin: 1.5px;
  cursor: pointer;

  a{
    text-decoration: none;
    color: #999;
  }
}

.nav-tabs li.active-tab {
  background-color: #039BE5;
  a {
    color: white;
    font-weight: 800;
  }
}

.nav-tabs li:not(.active-tab):hover {
  background-color: #039ae536;
}

.nav-tabs li:not(.active-tab):hover a {
  color: #039BE5
}

</style>