<template>
  <div class="container" style="display: flex;">
    <!-- <div class="imgWrapper" v-for="(img, index) in deviceImages" :key="index">
      <div class="img">
        <img :src="img.url" :alt="img.label" width="95%" height="95%" @click="openUploadDialog()" />
        <br>
        <span class="date"> {{ img.created_at }} <br> {{ img.created_at_time}}</span>                  
      </div>
    </div> -->
    <div class="imgWrapper">
      <div class="img-box">
        <img class="pwdm-img" src="./pwdm_image3.jpg" width="100%" height="100%" />
        <br />
        <p class="date" id="date1"> 2023년 8월 2일 <br /> 13시 20분 <span id="prev-date"> (35일 전) </span></p> 
      </div>
      <i class="fa-solid fa-angles-right fa-2xl"></i>
      <div class="img-box">
        <img class="pwdm-img" src="./pwdm_image1.jpg" width="100%" height="100%" />
        <br />
        <p class="date" id="date2">2023 9월 5일 <br /> 13시 20분</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        deviceImages: {
            type: Array,
        }
    },
}
</script>

<style scoped lang="scss">
.container {
  .imgWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    .img-box {
      position: relative;
      margin-bottom: 2%;
      .pwdm-img{
        border-radius: 3px;
      }
      .date {
        width: 90%;
        height: 15%;
        position: absolute;
        bottom: 0px;
        left: 5%;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        align-items: center;
        align-content: center;
        background-color: rgba(76,76,76,0.7);
        border-radius: 3px;
        color: white;
        padding-top:0.8%;
        min-height: 45px;
        
      }
    }
  }
  #date2{
      background-color:#039ae5ca;
      font-weight: 800;    
  }
  #prev-date{
    font-weight: 800;
    color: #039BE5;
  }

}
</style>