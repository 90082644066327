<template>
  <div>
    <CRow>
      <CCol col="12" xl="12">
        <CRow>
          <CCol col="12" xl="6">
            <CCard>
              <Summation ref="summationView" @click="onClick" />
            </CCard>
          </CCol>
          <CCol col="12" xl="6">
            <CCard>
              <Weather ref="weatherView"/>
            </CCard>
          </CCol>
        </CRow>
        <CRow>
          <CCol col="12" xl="12">
        <CCard>
          <Device ref="deviceView" @change="onChangedSite" prefix="srds_" />
        </CCard>
        </CCol>
      </CRow>
      </CCol>
      <CCol col="12" xl="8">
        <CCard style="min-height:418px;">
          <NewDevice ref="eventView" @update="updateSummation"/>
        </CCard>
      </CCol>
      <CCol col="12" xl="4">
        <CCard>
          <GoogleMap
            :sensor_type="8"
            :sensor_level="1"
            ref="googleMap"
            style="
              height: 100%;
              padding-top: 2%;
              padding-bottom: 2%;
              padding-left: 2%;
              padding-right: 2%;
            "
          />
        </CCard>
        <!-- <CCard>
          <EventLog
            ref="eventView"
            event_height="295" 
            @update="updateSummation"
            @click="onClickEvent"
            prefix="srds_"
          />
        </CCard> -->
      </CCol>
    </CRow>

    <EventDialog ref="eventDialog" @click="onClickEvent" />
  </div>
</template>

<script>
import GoogleMap from "@/views/srds/GoogleMap";

import Summation from "@/views/pwdm/Summation";
import EventDialog from "@/views/srds/EventDialog";
import Weather from "@/views/pwdm/Weather";
import EventLog from "@/views/srds/EventLog";
import Device from "@/views/pwdm/Device";
import Dashboard from "@/views/dashboard/Dashboard.vue";

import NewDevice from '@/views/pwdm/NewDevice'


export default {
  name: "PWDMBoard",
  extends: Dashboard,
  components: {
    GoogleMap,
    EventDialog,
    Weather,
    Summation,
    Device,
    EventLog,
    NewDevice
  },
  methods: {
    onClick(item) {
      // console.log('Dashboard::onClick Summation click item:', JSON.stringify(item,null,2))
      if (item.val != 0 && item.key != "DN") {
        var eventTable = this.$refs.eventView.getTable();
        this.$refs.eventDialog.show(item, eventTable.data);
      }
    },
    updateSummation(data) {
      var where = {
        and: [
          {
            status: 0,
            service_type: "srds",
          },
        ],
      };
      if (this.is_admin === false) {
        where.and.push({
          site_guid: {
            inq: this.user.site_guids,
          },
        });
      }
      this.$store
        .dispatch("rest/count", { model: "devices", where: where })
        .then((res) => {
          this.$refs.summationView.update(res.count, data);
        })
        .then(() => {
          var eventTable = this.$refs.eventView.getTable();
          eventTable.data.forEach((event) => {
            this.$refs.deviceView.setMarkerImage(event);
          });
        });
    },
  },
};
</script>
<style scoped>
*{
  font-family: 'Noto Sans KR', sans-serif;
}

</style>