<template>
  <div class="container">
    <div v-for="(item, index) in data" :key="index">
      <div class="wrap" :style="{ backgroundColor:item.color }">
<!-- title -->
        <div class="title">
          {{ item.title }}
        </div>
<!-- val -->
        <div class="sum_val" @click="onClick(item)">
          <span>
          {{ item.val }}
          </span>
        </div> 
      </div>
    </div> 
  </div>
</template>

<script>
import Summation from '@/views/dashboard/Summation'
import { SRDS_IMAGES as IMAGES } from "@/constants/constant"

export default {
  name: 'SRDSSummation',
  extends: Summation,
  data () {
    return {
      data: [
        { title: "전체", key: 'DG', val: 1, color: '#039BE5', icon: 'fa fa-home'}, 
        { title: "정상", key: 'DN', val: 0, color: '#43A047',icon: 'fa fa-check-square'}, 
        { title: "주의", key: 'DC', val: 0, color: '#FBC02D',icon: 'fa fa-warning' }, 
        { title: "위험", key: 'DW', val: 0, color: '#E65100',icon: 'fa fa-warning' }
      ]
    }
  },
  methods: {
    update(count, events) {
      this.data.forEach(elem => elem.val = 0);

      events.forEach(row => {
        var key = 'BG';
        var item = _.find(this.data, { key: key })
        switch (row.sensor_type) {
          case 1:
            key = row.level == 1 ? 'BN' : row.level == 2 ? 'BC' : 'BW';
            item = _.find(this.data, { key: key });
            item.val += 1;
            break;
          case 8:
            key = row.level == 2 ? 'DC' : 'DW';
            item = _.find(this.data, { key: key });
            item.val += 1;
            break;
        }
      })

      var item = _.find(this.data, { key : 'DN' })
      item.val = count - (_.find(this.data, {key:'DC'}).val + _.find(this.data, {key:'DW'}).val)

      item = _.find(this.data, { key : 'DG' })
      item.val = (_.find(this.data, { key : 'DN' }).val + _.find(this.data, {key:'DC'}).val + _.find(this.data, {key:'DW'}).val)
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.wrap {
  color: white;
  position: relative;
  width: 180px;
  height: 100px;
  margin: 5px;
  border-radius: 4px;
  transition: box-shadow .3s;
  background-color: #eee;
  &:hover {
    box-shadow: 0 0 11px rgba(33,33,33,.2); 
  }
}
.title {
  position: absolute;
  top: 5%;
  width: 100%;
  text-align: center;
}
.sum_val {
  position: absolute;
  font-size: 38px;
  font-weight: 600;
  top: 35%;
  left: 50%;;
  transform: translateX(-50%);
  bottom: -30px;
  cursor: pointer;
  width: 60px;
  height: 60px;
  background-color: rgba(255,255,255,0.8);
  border-radius:100%;
  justify-content: center;
  align-items: center;
  display: flex;
}
.sum_val span {
  margin: auto;
  color: #262626;
}
@media (max-width: 1700px) {
  .wrap {
    width: 120px;
  }
}
@media (max-width: 1326px) {
  .wrap {
    width: 100px;
  }
}
@media (max-width: 501px) {
  .wrap {
    width: 100px;
  }
  .container{
    width: 75%;
  }
}
@media (max-width: 365px) {
  .wrap {
    width: 100px;
  }
  .container{
    width: 100%;
  }
}
</style>