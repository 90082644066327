<template>
  <div class="containerBox">
    <div class="searchBar">
      <input class="form-control" type="text" placeholder="장치이름을 입력하세요" @input="searchData($event)" />
      <select class="selects" v-model="selected" @change="onClickTab(selected)">
        <option value="">장소를 선택하세요</option>
        <option v-for="(site, index) in siteTables" :value="index" :key="index">{{ site.title }}</option>
      </select> 
    </div>

    <div class="searchResult">
      <div class="group" v-for="(site, index) in siteTables" :key="index">
        <div class="group-item" v-for="(data, index) in site.data" :key="index" @click="sendEventData(data.guid)">
          <div class="title" id="psdm-title"><router-link :to="`srds_device/${data.guid}`">{{ data.name }}</router-link></div>
          <div class="underline"></div>
          <div class="imgWrapper">
            <div class="img" v-html="findCameraImg(data)"></div>
            <div class="battery" v-html="findBatteryImg(data)"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Device from '@/views/srds/Device'

export default {
    name: 'PWDMDevice',
    extends: Device,
}
</script>
<style dashdevice>

/* here */
.searchBar {
  display: flex;
  margin: 10px 15px 10px;
  width: 93%;

}

.searchBar .form-control {
  margin-left: 10px;
  margin-right: 10px;
}

.searchBar > select {
  padding-left: 2%;
  font-weight: 800;
  width: 30%;
}

.searchBar .selects select {
  width: 160px;
  height: 35px;
  margin-right: 10px;
}
.searchResult {
  height: 158px;
  overflow: auto;
  overflow-x: none;
}

.group {
  display: flex;
  flex-wrap: wrap;
  margin-left: 15px;
}

.group-item {
  background: #f0f0f099;
  border : 1px solid #eee;
  display: flex;
  flex-wrap: wrap;
  width: 190px;
  height: 140px;
  margin-top: 4px;
  margin-right: 15px;
  margin-left: 15px;
  border-radius: 10px;
  transition: box-shadow .3s;
  justify-content: center;
  align-items: center;
}

.group-item:hover {
  box-shadow: 0 0 11px rgba(33,33,33,.2);
}

.title {
  font-size: 18px;
  font-weight: bold;
}

#psdm-title{
  text-align: center;
  border-radius:7px;
}

.title > a {
  text-decoration: none; 
  color: black;

}

.underline {
  width: 170px;
  border-top: 1px dotted rgb(179, 179, 179);
  margin-bottom: 2%;
}

.iconWrapper {
  display: flex;
}

.level {
  margin-left: 10px;
}

.battery {
  bottom: 95px;
  left: 120px;
  z-index: 1;
  position: relative;
}

.rfstatus {
  margin-left: 20px;
}

.imgWrapper {
  cursor: pointer;
}

.sensor_time {
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  width: 115px;
  background-color: rgba(0,0,0,.7);
  position: relative !important;
  right: 5.5%;
}
.sensor_time > p{
  margin-bottom: -5px;
}

.searchResult::-webkit-scrollbar {
  background-color: #fff;
  margin-right: 10px;
  width: 7px !important;
  height: 8px;
}
.searchResult::-webkit-scrollbar-track {
  background-color: #fff;
}
.searchResult::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4xp solid #fff;
}

.ri-wifi-off-fill{
  color: rgb(230, 81, 0);
  /* font-weight: 900; */
  font-size: 1.25em;
  line-height: .05em;
  vertical-align: -0.075em;
}
@media (max-width: 414px) {
  .group {
    justify-content: center;
    align-items: center;
  }
}
</style>